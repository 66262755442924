<template>
  <a-layout id="" style="min-height: 100vh">
    <a-layout-header class="header">
      <div class="logo"/>
    </a-layout-header>
    <a-layout>
      <a-layout-sider v-model="collapsed" collapsible width="200">
        <div class="logo"/>
        <a-menu
            :defaultSelectedKeys="[$route.path]"
            :selectedKeys="[$route.path]"
            mode="inline"
            theme="dark"

        >
          <a-menu-item :key="'/'">
            <a-icon type="pie-chart"/>
            <span><router-link to="/">首页</router-link></span>
          </a-menu-item>

          <a-sub-menu key="'activity'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>后台管理</span>
                    </span>
            <a-menu-item :key="'/adminList'">
              <router-link to="/adminList">管理员列表</router-link>
            </a-menu-item>
            <a-menu-item :key="'/adminAuth'">
              <router-link to="/adminAuth">用户组</router-link>
            </a-menu-item>
            <a-menu-item :key="'/systeminfoedit'">
              <router-link to="/systeminfoedit">网站信息管理</router-link>
            </a-menu-item>


          </a-sub-menu>

<!--          <a-sub-menu key="'users'">-->
<!--                    <span slot="title">-->
<!--                        <a-icon type="admin"/>-->
<!--                        <span>用户管理</span>-->
<!--                    </span>-->
<!--            <a-menu-item :key="'/users'">-->
<!--              <router-link to="/users">用户管理</router-link>-->
<!--            </a-menu-item>-->

<!--          </a-sub-menu>-->
          <a-sub-menu key="'article'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>网站管理</span>
                    </span>
            <a-menu-item :key="'/Carousel'">
              <router-link to="/Carousel">首页轮播图管理</router-link>
            </a-menu-item>
            <a-menu-item :key="'/articleCategoryList'">
              <router-link to="/articleCategoryList">内容分类列表</router-link>
            </a-menu-item>
            <a-menu-item :key="'/video_list'">
              <router-link to="/video_list">视频管理</router-link>
            </a-menu-item>


          </a-sub-menu>
          <a-sub-menu key="'order'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>订单管理</span>
                    </span>
            <a-menu-item :key="'/order_list'">
              <router-link to="/order_list">订单列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="'calendar'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>日历管理</span>
                    </span>
            <a-menu-item :key="'/calendar'">
              <router-link to="/calendar">日历管理</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="'class'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>课程管理</span>
                    </span>
            <a-menu-item :key="'/classmanagement'">
              <router-link to="/classmanagement">课程管理</router-link>
            </a-menu-item>
            <a-menu-item :key="'/Certificatemanagement'">
              <router-link to="/Certificatemanagement">证书模板管理</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="'school'">
                    <span slot="title">
                        <a-icon type="admin"/>
                        <span>网站用户管理</span>
                    </span>
            <a-menu-item :key="'/users'">
              <router-link to="/users">网站用户管理</router-link>
            </a-menu-item>
            <a-menu-item :key="'/Courselist'">
              <router-link to="/Courselist">学生证书列表</router-link>
            </a-menu-item>
            <a-menu-item :key="'/LeaveUpList'">
              <router-link to="/LeaveUpList">教师证书列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item :key="9" @click="onLogout">
            <a-icon type="file"/>
            <span>退出</span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <!--            内部嵌入页面-->
      <router-view/>
    </a-layout>
  </a-layout>
</template>
<script>
import {Modal} from "ant-design-vue";

import Storage from "../common/storage";

export default {
  name: "index",
  components: {},
  data() {
    return {
      permissions: [],
      collapsed: false,
      loading: false,
    };
  },
  created() {
    let now = Date.now() / 1000;

    if(Storage.token_timeout > now ){
      console.log("now: ", now);
      console.log("token_timeout: ", Storage.token_timeout);
      console.log("isloging: ", sessionStorage.isloging);
    }else{
      Storage.token = "";
      this.$router.push({path: "/login"});
    }
    if (Storage.token === "") {
      window.vue.$router.push({path: "/login"});
    }
    // if (!sessionStorage.isloging) {
    //   Storage.token = "";
    //   window.vue.$router.push({path: "/login"});
    // }else{
    //   let times = new Date;
    //   let year=times.getFullYear();
    //   let month=times.getMonth()+1;
    //   this.nowdata=(year.toString() +"-"+ month.toString());
    //   console.log('now', this.nowdata);
    //   this.getTasklist(this.nowdata);
    //
    //   this.loading = true;
    // }
    if (now > Storage.token_timeout) {
      Storage.token = "";
      window.vue.$router.push({path: "/login"});
    }


    // Promise.all([this.getitem(), this.getresource()]).then(() => {
    //   this.loading = false;
    // }).catch((error) => {
    //   console.log(error)
    // })

  },
  methods: {

    onLogout() {
      Modal.confirm({
        title: "退出",
        content: "是否退出登录?",
        onOk() {
          Storage.token = "";
          window.vue.$router.push({path: "/login"});
        }
      });
    },
    async getTasklist(year_month) {
      console.log(year_month);
      this.loading = true;
      let result = await this.$get("/api/admin/task/list", {
        params: {
          uid: Storage.uid,
          token: Storage.token,
          year_month: year_month,
        }
      });

      if (result.status === true) {

        sessionStorage.calendarData = JSON.stringify(result.data);
        // console.log("日历Data" ,JSON.parse(sessionStorage.calendarData));
        this.loading = false;
      }

      this.loading = false;
    },
    reflesh() {
      console.log("开始刷新");
      this.$router.push({path: '/refresh', query: {path: this.$route.fullPath}})
    },
  }
};
</script>
<style>
.changerestate {
  color: #fff
}

.changerestate /deep/ .ant-radio-wrapper {
  color: #fff !important;
}

/*.router-link-active{background-color: #1890ff;}*/
.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  transform: scaleY(.0001);
  opacity: 0;
  transition: transform .15s cubic-bezier(.215, .61, .355, 1), opacity .15s cubic-bezier(.215, .61, .355, 1);
  content: "";
}
</style>
