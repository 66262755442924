import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../../src/layout/index.vue' // 主框架路径
import refresh from '../views/refresh.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Layout,
    redirect: '/home', //访问/重定向到/home


},
    {
        path: '/home',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('../views/Dashboard.vue'),
                name: 'home',

            },

            // {
            //     path: '/Criteria_list',
            //     name: 'Criteria_list',
            //     component: () => import('../views/Criteria_list.vue'),
            // },

            {
                path: '/users',
                name: 'users',
                component: () => import('../views/users.vue'),
            },
            {
                path: '/systeminfoedit',
                name: 'systeminfoedit',
                component: () => import('../views/systeminfoedit.vue'),
            },
            {
                path: '/logs',
                name: 'logs',
                component: () => import('../views/old/logs.vue'),
            },
            {
                path: '/adminList',
                name: 'adminList',
                component: () => import('../views/admin_list.vue'),
            },
            {
                path: '/adminAuth',
                name: 'adminAuth',
                component: () => import('../views/admin_auth.vue'),
            },
            {
                path: '/adminPermiss',
                name: 'adminPermiss',
                component: () => import('../views/admin_permiss.vue'),
            },
            {
                path: '/articleCategoryList',
                name: 'articleCategoryList',
                component: () => import('../views/articleCategoryList.vue'),
            },
            {
                path: '/articleadd',
                name: 'articleadd',
                component: () => import('../views/articleadd.vue'),
            },{
                path: '/articleedit',
                name: 'articleedit',
                hidden: true,
                component: () => import('../views/articleedit.vue'),
            },
            {
                path: '/calendar',
                name: 'calendar',
                component: () => import('../views/calendar.vue'),
            },
            {
                path: '/Carousel',
                name: 'Carousel',
                component: () => import('../views/Carousel.vue'),
            },{
                path: '/classmanagement',
                name: 'classmanagement',
                component: () => import('../views/classmanagement.vue'),
            },
            {
                path: '/video_list',
                name: 'video_list',
                component: () => import('../views/video_list.vue'),
            },{
                path: '/order_list',
                name: 'order_list',
                component: () => import('../views/order_list.vue'),
            },{
                path: '/Courselist',
                name: 'Courselist',
                component: () => import('../views/Courselist.vue'),
            },{
                path: '/LeaveUpList',
                name: 'LeaveUpList',
                component: () => import('../views/LeaveUpList.vue'),
            },{
                path: '/Certificatemanagement',
                name: 'Certificatemanagement',
                component: () => import('../views/Certificatemanagement.vue'),
            },{
                path: '/Certificateinfo',
                name: 'Certificateinfo',
                component: () => import('../views/Certificateinfo.vue'),
            },
            {
                path: '/PlayerVideo',
                name: 'PlayerVideo',
                component: () => import('../views/PlayerVideo.vue'),
            },
            // {
            //     path: '/log_list',
            //     name: 'log_list',
            //     component: () => import('../views/log_list.vue'),
            // },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue'),
    },
    {
        path: '/refresh',
        name: 'refresh',
        component: refresh
    },
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})

export default router
