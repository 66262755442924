<template>
    <a-config-provider :locale="zh_CN">
        <router-view/>
    </a-config-provider>
</template>
<script>
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import moment from "moment";
    import "moment/locale/zh-cn";

    moment.locale("zh-cn");
    export default {
        provide() {
            return {
                reload: this.reload
            }
        },

        data() {
            return {
                zh_CN
            };
        },
        methods:{
            reload() {
                // this.isRouterAlive = false
                // this.$nextTick(function () {
                //     this.isRouterAlive = true
                // })
                console.log("开始刷新");
            }
        },
    };
    // require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
    // Use only when you are using Webpack
</script>
<style>

    .green{color: #00AA00}
    .red{color: #ff4d4f}
</style>
