export default {
    data: {
        debug: true,
        precision:1000,

        server_url: "https://api.psychomotricite.org",
        video_url: "https://video.psychomotricite.org",
        www_url: "https://video.psychomotricite.org",
        // server_url: "https://isrpapi.6cd.com.cn",
        // map_server_url: "https://apis.map.qq.com",
        totla_capacity: 100
    }
}
