/**
 * 仓库
 */
export default {
    set uid(value) {
        localStorage.setItem('uid', value)
    },

    get uid() {
        return localStorage.getItem('uid')
    },
    set token(value) {
        localStorage.setItem('token', value)
    },

    get token() {
        return localStorage.getItem('token')
    },
    set token_timeout(value) {
        localStorage.setItem('token_timeout', value)
    },

    get token_timeout() {
        return localStorage.getItem('token_timeout')
    },
    set wx_code(value) {
        localStorage.setItem('wx_code', value)
    },
    get wx_code() {
        return localStorage.getItem('wx_code')
    },

    set myName(value) {
        localStorage.setItem('myName', value)
    },
    get myName() {
        return localStorage.getItem('myName')
    },


}
